
import { menuController } from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import moment from "moment";

export default defineComponent({
  name: "ApplyFilterMenu",
  emits: ["on-close"],
  setup(props, context) {
    const apply_status = reactive({
      data: [
        { name: "全部", value: "" },
        { name: "未审批", value: 0 },
        { name: "审批通过", value: 1 },
        { name: "审批未通过", value: 2 },
        { name: "审批中", value: 3 },
        { name: "审批撤回", value: 9 },
      ],
    });

    const onClose = () => {
      menuController.close();
    };

    let filter_name = ref("");
    let filter_status = ref("");
    let filter_type = ref(0);
    let filter_date_start = ref("");
    let filter_date_end = ref("");

    const keyNameHandler = (ev: any) => {
      const keyCode = window.event ? ev.keyCode : ev.which;
      if (keyCode === 13) {
        confirm();
      }
    };

    const checkStatus = (val: any) => {
      filter_status.value = val.value;
    };
    const resetTime = (val: any) => {
      if (val == 0) {
        filter_date_start.value = "";
      }
      if (val == 1) {
        filter_date_end.value = "";
      }
    };

    const reset = () => {
      filter_name.value = "";
      filter_status.value = "";
      filter_type.value = 0;
      filter_date_start.value = "";
      filter_date_end.value = "";
      confirm();
    };

    const confirm = () => {
      if (filter_date_start.value != "") {
        filter_date_start.value = moment(filter_date_start.value).format(
          "YYYY-MM-DD"
        );
      }
      if (filter_date_end.value != "") {
        filter_date_end.value = moment(filter_date_end.value).format(
          "YYYY-MM-DD"
        );
      }
      const params = {
        name: filter_name.value,
        status: filter_status.value,
        type: filter_type.value,
        dateStart: filter_date_start.value,
        dateEnd: filter_date_end.value,
      };
      context.emit("on-close", params);
    };

    return {
      apply_status,
      filter_name,
      filter_status,
      filter_type,
      filter_date_start,
      filter_date_end,
      onClose,
      checkStatus,
      resetTime,
      keyNameHandler,
      reset,
      confirm,
    };
  },
});
