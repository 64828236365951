
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import Header from "@/components/Header.vue";
import SearchBar from "@/components/SearchBar.vue";
import { menuController } from "@ionic/vue";
import ApplyFilterMenu from "@/views/attendance/apply/filter/FilterMenu.vue";

export default defineComponent({
  name: "FilterApply",
  components: {
    Header,
    SearchBar,
    ApplyFilterMenu,
  },
  setup() {
    const btnSizeRight = reactive({ data: ["38px"] });
    const btnIconRight = reactive({ data: ["iconshaixuan"] });

    let checkIndex = ref(0);

    const chips = reactive({
      data: [
        "全部",
        "请假",
        "销假",
        "补假",
        "异常打卡",
        "外出",
        "出差",
        "驻场",
      ],
    });

    const checkChip = (i: number) => {
      checkIndex.value = i;
    };

    const approvalList = reactive({
      data: [
        {
          id: "1",
          title: "高照迁提交的请假申请",
          type: "事假",
          startDate: "2021-03-02 09:00",
          endDate: "2021-03-02 16:30",
        },
      ],
    });

    const initDate = (r: any) => {
      let params = {
        name: "",
        status: 0,
        type: 0,
        startDate: "",
        endDate: "",
      };
      if (r != null) {
        params.name = r.name;
        params.status = r.status;
        params.type = r.type;
        params.startDate = r.dateStart;
        params.endDate = r.dateEnd;
      }
      //请求列表数据
      console.log("请求列表数据，参数：" + JSON.stringify(params));
    };

    onMounted(() => {
      initDate(null);
    });

    const router = useRouter();

    const goInto = (id: string) => {
      router.push({
        path: "",
        params: {
          id: id,
        },
      });
    };

    const searchHandler = (e: any) => {};

    const openMenuModal = (r: any) => {
      if (r == "0_icon") {
        menuController.enable(true, "ApplyFilterMenu");
        menuController.open("ApplyFilterMenu");
      }
    };

    const filterHandler = (r: any) => {
      menuController.close();
      initDate(r);
    };

    return {
      btnSizeRight,
      btnIconRight,
      checkIndex,
      checkChip,
      chips,
      approvalList,
      goInto,
      searchHandler,
      openMenuModal,
      filterHandler,
    };
  },
});
